import PropTypes from 'prop-types';
import {useRef, forwardRef, useMemo, useState} from 'react';

import {Root, Trigger, Portal, Content} from '@radix-ui/react-dropdown-menu';
import {IcChevronExpand} from '../../UI/Atoms/Icons';

export const SIZES = {
  md: 'text-base',
  lg: 'text-lg',
};

const Dropdown = forwardRef(
  ({dropdownTitle, attribute, size, children, className, ...args}, forwardedRef) => {
    const [open, setOpen] = useState(false);
    const triggerRef = useRef(null);
    let disabledStyles = '';
    const {disabled} = args;

    const dropdownSize = useMemo(() => SIZES[size] || SIZES.md, [size]);

    if (disabled) {
      disabledStyles = 'disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-300';
    }

    return (
      <div className="pointer-events-auto flex items-center gap-x-2">
        <Root attribute={attribute} open={open} onOpenChange={setOpen}>
          <Trigger
            data-testid="dropdownroot"
            ref={triggerRef}
            className={`flex h-9 items-center justify-between whitespace-nowrap rounded-md border-1 border-gray-300 bg-white px-3 py-2 pl-3 pr-2 font-source-sans-pro leading-5 text-gray-800 hover:border-gray-800 focus:border-gray-800 active:border-gray-300 active:bg-gray-100 [&[data-state='open']]:border-gray-300 [&[data-state='open']]:bg-gray-100 [&[data-state='open']_#trigger-icon]:rotate-180 ${disabledStyles} ${dropdownSize} ${className}`}
            disabled={disabled}
          >
            {dropdownTitle}
            <div className="origin-center pl-3 transition-transform duration-300">
              <div id="trigger-icon">
                <IcChevronExpand className="h-4 w-4" />
              </div>
            </div>
          </Trigger>
          <Portal>
            <Content
              ref={forwardedRef}
              sideOffset={5}
              align="start"
              className="z-10 flex flex-col gap-y-2 rounded-md border-1 bg-white py-3 drop-shadow-lg"
              onInteractOutside={() => setOpen(false)}
            >
              {children}
            </Content>
          </Portal>
        </Root>
      </div>
    );
  }
);

Dropdown.displayName = 'Dropdown';

Dropdown.defaultProps = {
  attribute: '',
  size: 'md',
  children: null,
  className: '',
};

Dropdown.propTypes = {
  /**
   *  Title shown for the dropdown
   */
  dropdownTitle: PropTypes.string.isRequired,
  /**
   * Optional attribute property to be used with Algolia
   */
  attribute: PropTypes.string,
  /**
   * The available size variants for the Input:
   */
  size: PropTypes.oneOf(Object.keys(SIZES)),
  /**
   *  Child nodes passed into the Dropdown component as children, ie: <ListItem />
   */
  children: PropTypes.node,
  /**
   * Custom class to be added to the dropdown trigger
   */
  className: PropTypes.string,
};

export default Dropdown;
